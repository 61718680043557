import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Resident } from "../../api";
import {
  CallConnected,
  Calling,
  ControlsSection,
  PoorConnection,
  TryingToReconnect,
} from "../../components";
import {
  SocketContext,
  WebSocketAction,
  WebSocketMessage,
} from "../../contexts";
import { SessionTokenContext } from "../../contexts/SessionTokenContext";
import {
  useCallMetrics,
  useConnectionStatus,
  usePermissions,
} from "../../hooks";
import {
  MetricBaseData,
  ROUTES,
  TestId,
  TrackType,
  addTrack,
  createCanvasStream,
  iceServers,
  makePostRequest,
  removeTrack,
  switchCamera,
  waitToCompleteIceGathering,
} from "../../utils";
import { Page } from "./LiveView.styles";
import { useLogger } from "../../hooks/useLogger";

enum LiveViewState {
  Calling = "Calling",
  Unavailable = "Unavailable",
  Connected = "Connected",
}
export enum ControlState {
  Disabled = "Disabled",
  Enabled = "Enabled",
  Unavailable = "Unavailable",
}
type MicControlState = Exclude<ControlState, ControlState.Unavailable>;
export type VideoConstraints = {
  audio: boolean;
  facingMode: "user" | { exact: "environment" };
};

const LiveView = () => {
  const location = useLocation();
  const [data] = useState(location.state as MetricBaseData);
  const navigate = useNavigate();
  const { microphone, camera } = usePermissions();

  const [state, setState] = useState<LiveViewState>(LiveViewState.Calling);
  const [residentMedia, setResidentMedia] = useState<MediaStream | null>(null);
  const [currentPeer, setCurrentPeer] = useState<RTCPeerConnection | null>(
    null
  );
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [cameraControl, setCameraControl] = useState<
    ControlState | undefined
  >();
  const [micControl, setMicControl] = useState<MicControlState>(
    ControlState.Enabled
  );
  const [constraints, setConstraints] = useState<VideoConstraints>({
    audio: false,
    facingMode: "user",
  });
  const [initializedCall, setInitializedCall] = useState(false);
  const [connected, setConnected] = useState(false);
  const [hasResetCamera, setHasResetCamera] = useState(false);
  const [endInProgress, setEndInProgress] = useState(false);
  const [online, setOnline] = useState(true);
  const { t } = useTranslation();
  const { sessionTokenWrapper } = useContext(SessionTokenContext);
  const [sessionToken] = sessionTokenWrapper;
  const { connectWS, sendMessage, messageHistory, webSocket } =
    useContext(SocketContext);
  const {
    connectedCall,
    startCall,
    calling,
    errorCall,
    stopCall,
    switchCameraView,
    switchMicOnOff,
    switchCameraOnOff,
    trackOngoingCallError,
    trackResidentOngoingCallError,
    trackResidentRateLimitError,
    trackCallDroppedError,
    trackNoAudioBytes,
    trackNoAudioReports,
  } = useCallMetrics();

  const isEndRequestedRef = useRef(false);
  const isCallCreatedRef = useRef(false);
  const isTrackRemovalRequested = useRef<TrackType | null>(null);
  const refreshInterval = useRef<NodeJS.Timer | null>(null);
  const { listenToStats, withPoorConnection, lastIabReceived } =
    useConnectionStatus(
      cameraControl === ControlState.Enabled,
      state === LiveViewState.Connected
    );
  const logger = useLogger();

  const logError = useCallback(
    (message: string, context?: any) => {
      logger.error(message, { ...context });
    },
    [logger]
  );

  const logInfo = useCallback(
    (message: string, context?: any) => {
      logger.info(message, { ...context });
    },
    [logger]
  );

  const handleAudioMetrics = useCallback(() => {
    if (connected) {
      if (lastIabReceived === 0) trackNoAudioBytes();
      else if (lastIabReceived === undefined) trackNoAudioReports();
    }
  }, [lastIabReceived, connected, trackNoAudioBytes, trackNoAudioReports]);

  const endCallAsync = useCallback(async () => {
    setEndInProgress(true);
    if (webSocket) {
      webSocket.close();
    } else {
      await makePostRequest(
        "call/end",
        { residentId: data.contact?.id },
        sessionToken
      );
    }

    currentPeer?.close();
  }, [currentPeer, data, sessionToken, webSocket]);

  useEffect(() => {
    if (location.state === null) {
      if (!data) {
        navigate({
          pathname: `../${ROUTES.DIRECTORY}`,
          search: location.search,
        });
      }
    } else {
      navigate(
        {
          pathname: `.`,
          search: location.search,
        },
        { replace: true }
      );
    }

    if (microphone !== null && microphone !== "granted") {
      navigate({
        pathname: `../${ROUTES.DIRECTORY}`,
        search: location.search,
      });
    }
  }, [microphone, location, navigate, data]);

  useEffect(() => {
    if (state === LiveViewState.Connected && !connected) {
      setConnected(true);
      connectedCall({
        videoEnabled: cameraControl === ControlState.Enabled,
        micEnabled: micControl === ControlState.Enabled,
      });
    }
  }, [state, cameraControl, micControl, connectedCall, connected]);

  const disconnectCall = useCallback(
    async (fromResident?: boolean, callDropped?: boolean) => {
      if (endInProgress) {
        return;
      }

      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }

      try {
        if (isCallCreatedRef.current) {
          await endCallAsync();
        } else {
          isEndRequestedRef.current = true;
        }
      } finally {
        stopCall(fromResident);

        let pathName: string;
        if (isCallCreatedRef.current) {
          pathName = callDropped
            ? `../${ROUTES.CALL_DROPPED_ERROR}`
            : `../${ROUTES.SESSION_ERROR}`;

          if (callDropped) trackCallDroppedError();
        } else {
          pathName = `../${ROUTES.DIRECTORY}`;
        }

        handleAudioMetrics();
        navigate({
          pathname: pathName,
          search: `${location.search}${
            isCallCreatedRef.current ? "&requestFeedback=true" : ""
          }`,
        });
      }
    },
    [
      location.search,
      navigate,
      stopCall,
      endCallAsync,
      endInProgress,
      trackCallDroppedError,
      handleAudioMetrics,
    ]
  );

  const setUpMediaStream = useCallback(async () => {
    setInitializedCall(true);
    const cameraStatus =
      camera === "granted" ? ControlState.Enabled : ControlState.Unavailable;
    setCameraControl(cameraStatus);

    let stream: MediaStream;
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: cameraStatus === ControlState.Enabled,
      });
      setMediaStream(stream);
    } catch (error: any) {
      logError("Error setting up media stream", error);
    }
  }, [camera, logError]);

  const setUpBlackFrames = useCallback(
    (stream: MediaStream, peer: RTCPeerConnection) => {
      if (stream.getVideoTracks().length === 0) {
        const canvasStream = createCanvasStream();

        canvasStream.getTracks().forEach((track) => {
          peer.addTrack(track, canvasStream);
        });
      }
    },
    []
  );

  const setUpPeerConnection = useCallback(
    async (
      stream: MediaStream,
      onPeerConnected: () => void,
      sdpOffer: string
    ) => {
      const callError = (e: any) => {
        if (data) {
          if (isCallCreatedRef.current) {
            endCallAsync();
          } else {
            isEndRequestedRef.current = true;
          }
        }

        errorCall();
        logError("Call Failed", e);
        handleAudioMetrics();
        navigate({
          pathname: isCallCreatedRef.current
            ? `../${ROUTES.SESSION_ERROR}`
            : `../${ROUTES.DIRECTORY}`,
          search: `${location.search}${
            isCallCreatedRef.current ? "&requestFeedback=true" : ""
          }`,
        });
        setTimeout(() => {
          window.alert(t("live_view.error"));
        }, 100);
      };

      if (isEndRequestedRef.current) {
        await endCallAsync();
        return;
      }

      logInfo("Create new RTCPeerConnection");
      const peer = new RTCPeerConnection({ iceServers });

      stream.getTracks().forEach((track) => {
        peer.addTrack(track, stream);
      });

      setUpBlackFrames(stream, peer);

      peer.ontrack = (e) => {
        if (e.streams.length) {
          setResidentMedia(e.streams[0]);
        }
      };

      peer.onconnectionstatechange = async () => {
        logInfo("RTCPeerConnection state change", {
          connectionState: peer.connectionState,
        });
        switch (peer.connectionState) {
          case "connected":
            onPeerConnected();
            break;
          case "closed":
          case "disconnected":
            peer.onconnectionstatechange = null;
            disconnectCall(true, !window.navigator.onLine);
            break;
          case "failed":
            peer.onconnectionstatechange = null;
            callError({ reason: "connection state change failed" });
            break;
          default:
            break;
        }
      };

      await peer.setRemoteDescription({ type: "offer", sdp: sdpOffer });

      let sdpAnswer: RTCSessionDescriptionInit | null =
        await peer.createAnswer();
      await peer.setLocalDescription(sdpAnswer);
      sdpAnswer = await waitToCompleteIceGathering(peer);
      logInfo("ICE gathering complete");

      return { peer, sdpAnswer };
    },
    [
      data,
      disconnectCall,
      endCallAsync,
      errorCall,
      handleAudioMetrics,
      location,
      navigate,
      setUpBlackFrames,
      t,
      logError,
      logInfo,
    ]
  );

  const websocketError = useCallback(
    (error: any) => {
      logError("WS error", error);
      // assume all websocket connection errors are ongoing call errors for now
      trackOngoingCallError();
      navigate({
        pathname: `../${ROUTES.ONGOING_CALL_ERROR}`,
      });
    },
    [navigate, trackOngoingCallError, logError]
  );

  // WS implementation
  useEffect(() => {
    const call = async () => {
      await setUpMediaStream();
      startCall({ microphone, camera });
      connectWS(data?.contact?.id as string, websocketError);
    };

    if (camera !== null && microphone !== null && !initializedCall) {
      call();
    }
  }, [
    camera,
    microphone,
    connectWS,
    data,
    initializedCall,
    startCall,
    setUpMediaStream,
    websocketError,
  ]);

  const resetCamera = useCallback(async () => {
    if (!hasResetCamera) {
      setHasResetCamera(true);

      if (cameraControl === ControlState.Enabled) {
        if (currentPeer) {
          await removeTrack(currentPeer, TrackType.Video, true);
        }

        // One single reset seems to be enough for camera feed
        setTimeout(async () => {
          if (currentPeer) {
            await addTrack(
              currentPeer,
              TrackType.Video,
              mediaStream,
              constraints.facingMode
            );
          }
        }, 250);
      } else {
        if (currentPeer) {
          await removeTrack(currentPeer, TrackType.Video);

          // Constant refresh needed for black screen
          refreshInterval.current = setInterval(async () => {
            await removeTrack(currentPeer, TrackType.Video);
          }, 2000);
        }
      }
    }
  }, [
    hasResetCamera,
    cameraControl,
    currentPeer,
    mediaStream,
    constraints.facingMode,
  ]);

  useEffect(() => {
    const startCall = async (sdpOffer: string) => {
      isCallCreatedRef.current = true;
      calling();

      const stream = mediaStream;
      if (!stream) {
        logError("No media stream when initiating call");
        return;
      }

      const onPeerConnected = () => {};

      const peerResponse = await setUpPeerConnection(
        stream,
        onPeerConnected,
        sdpOffer
      );

      if (peerResponse) {
        const { peer, sdpAnswer } = peerResponse;
        if (peer.connectionState !== "failed") {
          sendMessage(WebSocketAction.startCall, {
            sdpAnswer: sdpAnswer?.sdp,
            residentId: data.contact?.id,
          });
          setCurrentPeer(peer);
          if (isTrackRemovalRequested.current) {
            removeTrack(peer, isTrackRemovalRequested.current);
          }
          listenToStats(peer);
        }
      }
    };

    const monitorMessages = async () => {
      if (!messageHistory.length) {
        return;
      }

      const lastMessage = messageHistory[messageHistory.length - 1];
      if (lastMessage?.sdpOffer && !isCallCreatedRef.current) {
        startCall(lastMessage.sdpOffer);
      } else if (lastMessage?.message) {
        const message = lastMessage.message as WebSocketMessage;

        switch (message) {
          case WebSocketMessage.ResidentConnected:
            setState(LiveViewState.Connected);
            await resetCamera();
            break;
          case WebSocketMessage.ClientDisconnect:
          case WebSocketMessage.SessionEnded:
            await disconnectCall(true);
            break;
          case WebSocketMessage.ResidentRateLimit:
            webSocket?.close();
            trackResidentRateLimitError();
            navigate({
              pathname: `../${ROUTES.ONGOING_CALL_ERROR}`,
            });
            break;
          case WebSocketMessage.ApOngoingCall:
            webSocket?.close();
            trackOngoingCallError();
            navigate({
              pathname: `../${ROUTES.ONGOING_CALL_ERROR}`,
            });
            break;
          case WebSocketMessage.ResidentOngoingCall:
          case WebSocketMessage.ResidentOngoingCallMAP:
            trackResidentOngoingCallError();
            webSocket?.close();
            navigate({
              pathname: `../${ROUTES.RESIDENT_UNAVAILABLE}`,
            });
            break;
          case WebSocketMessage.ExistingSession:
          case WebSocketMessage.InternalServerError:
          case WebSocketMessage.InvalidSession:
          case WebSocketMessage.DingCreateError:
            webSocket?.close();
            navigate({
              pathname: `../${ROUTES.VALIDATION_ERROR}`,
            });
            break;
          default:
            break;
        }
      }
    };

    monitorMessages();
  }, [
    messageHistory,
    calling,
    mediaStream,
    data,
    sendMessage,
    setUpPeerConnection,
    endCallAsync,
    disconnectCall,
    navigate,
    webSocket,
    trackResidentRateLimitError,
    trackOngoingCallError,
    trackResidentOngoingCallError,
    listenToStats,
    logError,
    resetCamera,
  ]);

  useEffect(() => {
    return () => {
      if (mediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [mediaStream]);

  const handleCamera = async () => {
    switch (cameraControl) {
      case ControlState.Unavailable:
        return;
      case ControlState.Enabled:
        setCameraControl(ControlState.Disabled);
        if (currentPeer) {
          removeTrack(currentPeer, TrackType.Video);
        } else {
          isTrackRemovalRequested.current = TrackType.Video;
        }
        break;
      case ControlState.Disabled:
        setCameraControl(ControlState.Enabled);
        currentPeer &&
          (await addTrack(
            currentPeer,
            TrackType.Video,
            mediaStream,
            constraints.facingMode
          ));
        break;
    }
    switchCameraOnOff();
    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }
  };

  const handleMic = async () => {
    if (micControl === ControlState.Disabled) {
      setMicControl(ControlState.Enabled);
      currentPeer &&
        (await addTrack(currentPeer, TrackType.Audio, mediaStream));
    } else {
      setMicControl(ControlState.Disabled);
      if (currentPeer) {
        removeTrack(currentPeer, TrackType.Audio);
      } else {
        isTrackRemovalRequested.current = TrackType.Audio;
      }
    }
    switchMicOnOff();
  };

  const handleRotate = async () => {
    if (constraints.facingMode === "user") {
      setConstraints({ ...constraints, facingMode: { exact: "environment" } });
      currentPeer &&
        (await switchCamera(
          currentPeer,
          { exact: "environment" },
          mediaStream
        ));
    } else {
      setConstraints({ ...constraints, facingMode: "user" });
      currentPeer && (await switchCamera(currentPeer, "user", mediaStream));
    }
    switchCameraView();
  };

  const hasPermissions = () => data !== null && microphone === "granted";

  window.addEventListener("online", () => {
    setOnline(true);
  });
  window.addEventListener("offline", () => {
    setOnline(false);
  });

  return (
    <Page data-testid={TestId.LiveView}>
      {hasPermissions() && (
        <>
          {state === LiveViewState.Calling && (
            <Calling
              displayName={data.contact?.name as string}
              constraints={constraints}
              guestMedia={mediaStream}
              cameraControl={cameraControl}
            />
          )}

          {state === LiveViewState.Connected && (
            <CallConnected
              contact={data.contact as Resident}
              audioStream={residentMedia}
              constraints={constraints}
              guestMedia={mediaStream}
              cameraControl={cameraControl}
            />
          )}

          {withPoorConnection && <PoorConnection />}

          {!online && <TryingToReconnect />}

          <ControlsSection
            micControl={micControl}
            cameraControl={cameraControl}
            onDisconnect={() => disconnectCall(false)}
            handleMic={handleMic}
            handleCamera={handleCamera}
            handleRotate={handleRotate}
            endInProgress={endInProgress}
            withPoorConnection={withPoorConnection}
          />
        </>
      )}
    </Page>
  );
};

export default LiveView;
