export const ROUTES = {
  DIRECTORY: "directory",
  LIVE: "live",
  VALIDATION_ERROR: "/error",
  SESSION_ERROR: "/session-error",
  CALL_DROPPED_ERROR: "/call-dropped",
  DISABLED_SERVICE: "/not-available",
  ONGOING_CALL_ERROR: "/calling-unavailable",
  RESIDENT_UNAVAILABLE: "/resident-unavailable",
  TIMESTAMP_ERROR: "/timestamp-error",
  TAMPER_ERROR: "/unavailable",
};
