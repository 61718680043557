export enum TestId {
  Directory = "Directory",
  DirectoryLink = "DirectoryLink",
  Building = "Building",
  PermissionAlert = "PermissionAlert",
  SearchBox = "SearchBox",
  NoResults = "NoResults",
  Contact = "Contact",
  OutOfRange = "OutOfRange",
  LocationUnknown = "LocationUnknown",
  LoadingIndicator = "LoadingIndicator",
  UpdatingIndicator = "UpdatingIndicator",
  PrivacyNotice = "PrivacyNotice",
  PrivacyNoticeButton = "PrivacyNoticeButton",
  PrivacyNoticeInput = "PrivacyNoticeInput",
  LocationDetails = "LocationDetails",
  LiveView = "LiveView",
  LiveViewControls = "LiveViewControls",
  LiveViewControlsRotate = "LiveViewControlsRotate",
  LiveViewControlsCamera = "LiveViewControlsCamera",
  LiveViewControlsMic = "LiveViewControlsMic",
  CameraDisabled = "CameraDisabled",
  CameraUnavailable = "CameraUnavailable",
  CameraAvailable = "CameraAvailable",
  LiveViewControlsMicEnabled = "LiveViewControlsMicEnabled",
  LiveViewControlsMicDisabled = "LiveViewControlsMicDisabled",
  LiveViewControlsDisconnect = "LiveViewControlsDisconnect",
  NotFound = "NotFound",
  ValidationError = "ValidationError",
  SessionError = "SessionError",
  CallDroppedError = "CallDroppedError",
  ServiceDisabled = "ServiceDisabled",
  BrowserNotSupported = "BrowserNotSupported",
  LanguageSelect = "LanguageSelect",
}
