import { AxiosError } from "axios";
import { axiosInstance } from "../api";
import { API } from "./constants";

export const makePostRequest = async (
  uri: string,
  payload: any,
  sessionToken: string
) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      [API.SESSION_TOKEN_HEADER]: sessionToken,
    },
  };

  try {
    const response = await axiosInstance.post(
      uri,
      JSON.stringify(payload),
      requestOptions
    );

    return response.data;
  } catch (error) {
    const message = (error as AxiosError)?.message || undefined;
    throw new Error(message);
  }
};

export const iceServers = [];
