export interface Environment {
  basePath?: string;
  heapId?: string;
  consentScriptURL?: string;
  basename?: string;
  stage?: string;
  loggerUrl: string;
  socketUrl: string;
  browserLanguage?: string;
  displayFeedback?: boolean;
}

export const environment = (window as any).ENVIRONMENT as Environment;
