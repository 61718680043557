import icon from "./phone_camera_off.png";

enum TrackType {
  Video = "video",
  Audio = "audio",
}
type TrackTypes = TrackType.Audio | TrackType.Video;

const waitToCompleteIceGathering = (pc: RTCPeerConnection) => {
  return new Promise<RTCSessionDescription | null>((resolve) => {
    pc.addEventListener("icegatheringstatechange", (e: any) => {
      if (e.target.iceGatheringState === "complete") {
        resolve(pc.localDescription);
      }
    });
  });
};

const switchCamera = async (
  peer: RTCPeerConnection,
  facingMode: "user" | { exact: "environment" } = "user",
  mediaStream?: MediaStream
) => {
  if (!mediaStream) {
    return;
  }

  try {
    const videoTracks = mediaStream.getVideoTracks();
    videoTracks.forEach((track) => {
      track.stop();
    });

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode },
    });
    const newVideoTrack = stream.getVideoTracks();

    if (newVideoTrack.length <= 0) {
      return;
    }

    mediaStream.removeTrack(videoTracks[0]);
    mediaStream.addTrack(newVideoTrack[0]);

    const sender = peer
      .getSenders()
      .find((sender) => sender.track && sender.track.kind === TrackType.Video);

    if (sender) {
      sender.replaceTrack(newVideoTrack[0]);
    }
  } catch (error) {}
};

const createCanvasStream = (noImage: boolean = false) => {
  const canvasElement = document.createElement("canvas");
  canvasElement.width = 390;
  canvasElement.height = 844;
  const ctx = canvasElement.getContext("2d");

  const image = new Image();
  image.src = icon;

  image.onload = () => {
    const drawCanvas = () => {
      if (ctx) {
        ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);

        if (!noImage) {
          ctx.drawImage(
            image,
            canvasElement.width / 2 - image.width / 2,
            canvasElement.height / 2 - image.height / 2
          );
        }
      }
      setTimeout(() => {
        requestAnimationFrame(drawCanvas);
      }, 1000);
    };
    drawCanvas();
  };

  return canvasElement.captureStream();
};

const removeTrack = async (
  peer: RTCPeerConnection,
  kind: TrackTypes,
  noImage: boolean = false
) => {
  const sender = peer.getSenders().filter((e) => e.track?.kind === kind);
  sender && sender.forEach((sender) => peer.removeTrack(sender));

  if (kind === TrackType.Audio) {
    return;
  }

  const canvasStream = createCanvasStream(noImage);
  await addTrack(peer, TrackType.Video, canvasStream);
};

const addTrack = async (
  peer: RTCPeerConnection,
  kind: TrackTypes,
  mediaStream?: MediaStream,
  facingMode?: "user" | { exact: "environment" }
) => {
  if (!mediaStream) {
    return;
  }
  const tracks = mediaStream.getTracks().filter((track) => track.kind === kind);

  if (tracks.length > 0 && tracks[0] != null) {
    peer.getSenders().forEach((sender) => {
      if (sender.track == null || sender.track.kind === kind) {
        sender.replaceTrack(tracks[0]).catch(() => {});
      }
    });

    peer.getTransceivers().forEach((transceiver) => {
      if (
        transceiver.sender.track?.kind == null ||
        transceiver.sender.track.kind === kind
      ) {
        transceiver.direction = "sendrecv";
      }
    });
  } else {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: kind === TrackType.Audio,
      video: kind === TrackType.Video ? { facingMode } : false,
    });
    const newTracks = stream.getTracks();
    if (newTracks.length <= 0) {
      return;
    }
    newTracks.forEach((track) => {
      const inArr = mediaStream
        .getTracks()
        .some((current) => current.id === track.id);
      if (!inArr) {
        mediaStream.addTrack(track);
        peer.addTrack(track, mediaStream);
      }
    });
  }
};

export {
  waitToCompleteIceGathering,
  addTrack,
  removeTrack,
  switchCamera,
  TrackType,
  createCanvasStream,
};
