export const CC_PRIVACY_COOKIE = "cc-privacy-cookie";
export const PRIVACY_COOKIE = "privacy-cookie";
export const I18N_KEY = "i18nSelection";
export const PENDING_METRICS_KEY = "pendingMetrics";
export const API = {
  RESIDENTS_LIST: "/residents",
  DIRECTORY: "/directory",
  RESIDENTS_LIST_PARAM: `tagId`,
  SESSION_TOKEN_HEADER: "x-session-id",
  SESSION_EXPIRATION_HEADER: "x-session-expiry",
};

const NODE_ENV = process.env["NODE_ENV"];
export const isLocalBuild = NODE_ENV === "development";
export const isDeployedBuild = NODE_ENV === "production";

const { hostname } = window.location;

export const isProd = hostname === "ring.com";
export const isQA = hostname === "www.qa.ring.com";
export const isDev =
  hostname === "www.dev.ring.com" || hostname === "localhost";

export const metricEventPrefix = "AVC -";
export const metricEvents = {
  property: `${metricEventPrefix} Property`,
  callConnected: `${metricEventPrefix} Call Connected`,
  callPermissions: `${metricEventPrefix} Call Permissions Status`,
  callResident: `${metricEventPrefix} Initialize Call`,
  cancelCall: `${metricEventPrefix} Cancel Call Prompt`,
  cameraPermissionGranted: `${metricEventPrefix} Camera Permission Granted`,
  cameraPermissionDenied: `${metricEventPrefix} Camera Permission Denied`,
  micPermissionGranted: `${metricEventPrefix} Microphone Permission Granted`,
  micPermissionDenied: `${metricEventPrefix} Microphone Permission Denied`,
  confirmPrivacyNotice: `${metricEventPrefix} Confirm Privacy Notice`,
  closePrivacyNotice: `${metricEventPrefix} Close Privacy Notice`,
  viewPrivacyNotice: `${metricEventPrefix} View Privacy Notice`,
  guestEndedCall: `${metricEventPrefix} Guest Ended Call`,
  residentEndedCall: `${metricEventPrefix} Resident Ended Call`,
  guestSwitchedCameraView: `${metricEventPrefix} Guest Switched Camera View`,
  guestSwitchedCamera: `${metricEventPrefix} Guest Switched Camera On/Off During Call`,
  guestCameraOn: `${metricEventPrefix} Guest Camera Always On During Call`,
  guestCameraOff: `${metricEventPrefix} Guest Camera Always Off During Call`,
  guestSwitchedMicrophone: `${metricEventPrefix} Guest Switched Microphone On/Off During Call`,
  guestMicrophoneOn: `${metricEventPrefix} Guest Microphone Always On During Call`,
  guestMicrophoneOff: `${metricEventPrefix} Guest Microphone Always Off During Call`,
  callFinished: `${metricEventPrefix} Call Finished`,
  timerExpired: `${metricEventPrefix} Timer Expired`,
  ongoingCallError: `${metricEventPrefix} Ongoing Call Error`,
  residentOngoingCallError: `${metricEventPrefix} Resident Ongoing Call Error`,
  residentRateLimitError: `${metricEventPrefix} Resident Rate Limit Error`,
  apNotAvailable: `${metricEventPrefix} Access Point not available Error`,
  poorConnection: `${metricEventPrefix} Poor Connection During Call`,
  changeLanguage: `${metricEventPrefix} Change Language`,
  callDropped: `${metricEventPrefix} Call Dropped`,
  tamperError: `${metricEventPrefix} Tamper Error`,
  timestampError: `${metricEventPrefix} Timestamp Error`,
  initialLanguage: `${metricEventPrefix} Initial Language`,
  noIncomingBytes: `${metricEventPrefix} No Incoming Audio Bytes After Completed Call`,
  noInboundAudioReports: `${metricEventPrefix} No Incoming Audio Reports After Completed Call`,
};

export const ringUrl = "https://ring.com";
export const ringTermsUrl = "https://ring.com/terms";
export const ringPrivacyUrl = "https://ring.com/privacy-notice";

export const surveyUrl =
  "https://amazonexteu.qualtrics.com/jfe/form/SV_3q6H0fwHA5psRF4";
